import { DateRange } from '@/model'
import { forEach } from 'lodash-es'
import moment, { type Moment } from 'moment'

const minDate = ['1900-01-01 00:00:00', '0001-01-01 00:00:00']
const dateStrTemplate = 'YYYY-MM-DD HH:mm:ss'

/**
 *
 * @param component 需要注册的组件
 * @param alias 组件别名
 * @returns any
 */
export const withInstall = <T>(component: T, alias?: string) => {
  const comp = component as any
  comp.install = (app: any) => {
    app.component(comp.name || comp.displayName, component)
    if (alias) {
      app.config.globalProperties[alias] = component
    }
  }
  return component as T & Plugin
}

/**
 * @param str 需要转下划线的驼峰字符串
 * @returns 字符串下划线
 */
export const humpToUnderline = (str: string): string => {
  return str.replace(/([A-Z])/g, '-$1').toLowerCase()
}

/**
 * @param str 需要转驼峰的下划线字符串
 * @returns 字符串驼峰
 */
export const underlineToHump = (str: string): string => {
  if (!str) return ''
  return str.replace(/\-(\w)/g, (_, letter: string) => {
    return letter.toUpperCase()
  })
}

/**
 * 驼峰转横杠
 */
export const humpToDash = (str: string): string => {
  return str.replace(/([A-Z])/g, '-$1').toLowerCase()
}

export const setCssVar = (prop: string, val: any, dom = document.documentElement) => {
  dom.style.setProperty(prop, val)
}

export const getCssVar = (prop: string, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(prop)
}

/**
 * 查找数组对象的某个下标
 * @param {Array} ary 查找的数组
 * @param {Functon} fn 判断的方法
 */
// eslint-disable-next-line
export const findIndex = <T = Recordable>(ary: Array<T>, fn: Fn): number => {
  if (ary.findIndex) {
    return ary.findIndex(fn)
  }
  let index = -1
  ary.some((item: T, i: number, ary: Array<T>) => {
    const ret: T = fn(item, i, ary)
    if (ret) {
      index = i
      return ret
    }
  })
  return index
}

export const trim = (str: string) => {
  return str.replace(/(^\s*)|(\s*$)/g, '')
}

/**
 * @param {Date | number | string} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time: Date | number | string, fmt: string) {
  if (!time) return ''
  else {
    const date = new Date(time)
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'H+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds()
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
        )
      }
    }
    return fmt
  }
}

/**
 * 生成随机字符串
 */
export function toAnyString() {
  const str: string = 'xxxxx-xxxxx-4xxxx-yxxxx-xxxxx'.replace(/[xy]/g, (c: string) => {
    const r: number = (Math.random() * 16) | 0
    const v: number = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString()
  })
  return str
}

/**
 * 首字母大写
 */
export function firstUpperCase(str: string) {
  return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
}

/**
 * 把对象转为formData
 */
export function objToFormData(obj: Recordable) {
  const formData = new FormData()
  Object.keys(obj).forEach((key) => {
    formData.append(key, obj[key])
  })
  return formData
}

/**
 * 对象合并
 */
export function objMerge(target: any, source: any) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach((property) => {
    const sourceProperty = source[property]
    if (sourceProperty === null) {
      target[property] = null
    } else if (typeof sourceProperty === 'object') {
      target[property] = objMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * 填充对象
 */
export function objFill(target: any, source: any, map?: any) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(target).forEach((property) => {
    let key = (map && map[property]) || property
    if (typeof target[property] === 'object' && map) {
      key = map[property]._n
    }

    const sourceProperty = source[key]
    if (sourceProperty !== undefined) {
      if (
        sourceProperty !== null &&
        !Array.isArray(sourceProperty) &&
        typeof sourceProperty === 'object'
      ) {
        target[property] = objFill(target[property], sourceProperty, map[property])
      } else {
        target[property] = sourceProperty
      }
    }
  })
  return target
}

/**
 * 判断是否为空
 */
export function isEmpty(str: string | null | undefined): boolean {
  return !str || str === null || str === ''
}

/**
 * 字符串转moment
 */
export function toMoment(value: string): Moment | null {
  return hasDate(value) ? moment(value, dateStrTemplate) : null
}

/**
 * 取当前日期
 */
export function getDateNow(): string {
  const now = moment()
  return formatDateFromMoment(now)
}

/**
 * 获取今天 00:00:00 时间
 */
export function getStartOfToday(): string {
  const startOfToday = moment().startOf('day') // 获取今天的 00:00:00
  return formatDateTimeFromMoment(startOfToday)
}

/**
 * 取当前时间
 */
export function getDateTimeNow(): string {
  const now = moment()
  return formatDateTimeFromMoment(now)
}

/**
 * 取当月到当天的时间区间
 * @returns
 */
export function getDateRangeForCurrentMonthToNow(): DateRange {
  const start = moment().startOf('month').format('YYYY-MM-DD')
  const end = getDateNow()
  return new DateRange(start, end)
}

/**
 * 是否为有效的日期格式
 */
export function hasDate(val: string): boolean {
  return val !== undefined && val !== '' && val !== null && minDate.indexOf(val) < 0
}

/**
 * moment 格化化日期输出
 * YYYY-MM-DD
 */
export function formatDateFromMoment(value: Moment): string {
  return value.format(dateStrTemplate)
}

/**
 * moment 格化化日期输出
 * YYYY-MM-DD HH:mm:ss
 */
export function formatDateTimeFromMoment(value: Moment): string {
  return value.format(dateStrTemplate)
}

/**
 * 格式化日期
 * YYYY-MM-DD
 */
export function formatDate(value: string, def = ''): string {
  return hasDate(value) ? moment(value, dateStrTemplate).format('YYYY-MM-DD') : def
}

/**
 * 格式化日期
 * YYYY-MM-DD HH:mm
 */
export function formatDateTimeForMinute(value: string, def = ''): string {
  return hasDate(value) ? moment(value, dateStrTemplate).format('YYYY-MM-DD HH:mm') : def
}

/**
 * 转换成当天最后时刻
 */
export function toDayEnd(value: string): string {
  if (!value || value === '') {
    return value
  }
  const date = moment(value).add(1, 'days').add(-1, 'milliseconds')
  return date.format('YYYY-MM-DD HH:mm:ss')
}

/**
 * 递归遍历
 */
export function processArray(arr, func, propsMap?) {
  const childrenKey = propsMap && propsMap.children ? propsMap.children : 'children'
  for (const item of arr) {
    let mark
    if (item[childrenKey] && item[childrenKey].length > 0) {
      mark = processArray(item[childrenKey], func)
      if (!mark) {
        return mark
      }
    }
    if (!func(item)) {
      return false
    }
  }
  return true
}

/**
 * 递归对象转换
 */
export function arrayToNestedObject(arr, func) {
  const result: any[] = []
  arr.forEach((x) => {
    let list: any[] = []
    if (x.children && x.children.length > 0) {
      const cur = arrayToNestedObject(x.children, func)
      list = cur
    }
    result.push(func(x, list))
  })
  return result
}
