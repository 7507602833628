import axios, { AxiosError } from 'axios'
import { defaultRequestInterceptors, defaultResponseInterceptors } from './config'
import { isEmpty } from '@/utils/index'
import ui from '@/utils/ui'
import router from '@/router'; // 确保路由正确导入

import { AxiosInstance, InternalAxiosRequestConfig, RequestConfig, AxiosResponse } from './types'
import { ElMessage } from 'element-plus'
import { REQUEST_TIMEOUT } from '@/constants'
import { useUserStoreWithOut } from '@/store/modules/user'

export const PATH_URL = import.meta.env.VITE_API_BASE_PATH

const abortControllerMap: Map<string, AbortController> = new Map()

const axiosInstance: AxiosInstance = axios.create({
  timeout: REQUEST_TIMEOUT,
  baseURL: PATH_URL
})

axiosInstance.interceptors.request.use((res: InternalAxiosRequestConfig) => {
  const controller = new AbortController()
  const url = res.url || ''
  res.signal = controller.signal
  abortControllerMap.set(url, controller)
  return res
})

axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => {
    const url = res.config.url || ''
    abortControllerMap.delete(url)
    // 这里不能做任何处理，否则后面的 interceptors 拿不到完整的上下文了
    return res
  },
  (error: AxiosError) => {
    const { status, data }: any = error.response
    if (status == 401) {
      ElMessage.error('登录已失效或该账号在别处登录.')
      const userStore = useUserStoreWithOut()
      userStore.logout().then(
        () => {
          router.replace('/login')
        }
      )
    } else if (status === 403 && data && !isEmpty(data.errorMsg)) {
      ElMessage.error(data.errorMsg)
    } else {
      ElMessage.error(error.message)
    }
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.request.use(defaultRequestInterceptors)
axiosInstance.interceptors.response.use(defaultResponseInterceptors)

const showLoading = (loader?: RequestLoader): any => {
  let result
  if (loader === true) {
    const instance = ui.loading()
    result = {
      close: () => instance.close()
    }
  } else if (loader) {
    loader(true)
    result = {
      close: () => loader(false)
    }
  }
  return result
}

const service = {
  request: (config: RequestConfig, loader?: RequestLoader) => {
    return new Promise((resolve, reject) => {
      if (config.interceptors?.requestInterceptors) {
        config = config.interceptors.requestInterceptors(config as any)
      }

      const loading = showLoading(loader)
      axiosInstance
        .request(config)
        .then((res: any) => {
          if (!res.success) {
            ui.message.error(res.errorMsg)
          }
          resolve(res)
        })
        .catch((err: any) => {
          reject(err)
        })
        .finally(() => {
          if (loading) {
            loading.close()
          }
        })
    })
  },
  cancelRequest: (url: string | string[]) => {
    const urlList = Array.isArray(url) ? url : [url]
    for (const _url of urlList) {
      abortControllerMap.get(_url)?.abort()
      abortControllerMap.delete(_url)
    }
  },
  cancelAllRequest() {
    for (const [_, controller] of abortControllerMap) {
      controller.abort()
    }
    abortControllerMap.clear()
  }
}

export default service
